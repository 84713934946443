import React from 'react'

import ImageWebp from 'src/components/ImageWebp'
import Tab from 'src/components/Tab'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'

import AdvantagesOfInterCardsJSON from './../../assets/data/AdvantagesOfInterCards.json'
import Check from './../../assets/images/check.png'

import { Benefits, Button } from './style'

type modalProps = {
  setIsModalOpen: Function;
  setDataLayer: Function;
  oneLink: string;
}

type ItemProps = {
  card: string;
  image: string;
  alt: string;
  benefits: benefitsProps[];
  descriptionSpot: descriptionSpotProps[];
}

type benefitsProps = {
  text: string;
  hasLink?: string;
  linkTitle?: string;
}

type descriptionSpotProps = {
  icon?: string;
  text: string;
  hasLink?: string;
  linkTitle?: string;
}

const Beneficios = ({ setIsModalOpen, setDataLayer, oneLink }: modalProps) => {
  const tabName = AdvantagesOfInterCardsJSON.map((item: ItemProps) => item.card)
  const WIDTH_MD = 768
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Cartões Inter Mastercard',
    element_action: 'click button',
    element_name: 'Solicitar cartão de crédito gratuito',
  }

  return (
    <Benefits id='beneficios'>
      <div className='container py-5'>
        <h2 className='fs-22 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-md-center text-grayscale--500 mb-3'>
          Cartões Inter Mastercard
        </h2>
        <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 text-md-center text-grayscale--400'>
          <span className='d-block d-md-inline'>Segurança e flexibilidade nos pagamentos, além de muitos </span>
          benefícios e promoções <span className='d-lg-block'>pra facilitar o seu dia a dia.</span>

        </p>
        <Tab tabDifColor classDiv='mt-5' items={tabName}>
          {AdvantagesOfInterCardsJSON.map((card: ItemProps) => (
            <div key={card.card} className='col-12 mt-5'>
              <div className='row align-items-center'>
                <div className='col-12 col-lg-5 d-flex justify-content-center'>
                  <ImageWebp pathSrc={card.image} altDescription={card.alt} arrayNumbers={[ 200, 280, 300, 300 ]} />
                </div>
                <div className='col-lg-7 mt-3 mt-lg-0'>
                  <div className='row pb-3'>
                    {card.benefits.map((benefit: benefitsProps) => (
                      <div key={benefit.text} className='col-md-6 d-flex align-items-center'>
                        <div className='icon-circle d-flex justify-content-center align-items-center mr-md-3'>
                          <img src={Check} alt='Check' width='29' height='29' />
                        </div>
                        {benefit.hasLink ? (
                          <span
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                section_name: 'Cartões Inter Mastercard',
                                element_action: 'click button',
                                element_name: `${benefit.linkTitle} + ${card.card}` || 'null',
                                redirect_url: benefit.hasLink,
                              })
                            }}
                          >
                            <p className='fs-16 lh-20 text-grayscale--500 fw-600 mt-3 ml-2 ml-md-2' dangerouslySetInnerHTML={{ __html: benefit.text }} />
                          </span>
                        ) : (
                          <p className='fs-16 lh-20 text-grayscale--500 fw-600 mt-3 ml-2 ml-md-2' dangerouslySetInnerHTML={{ __html: benefit.text }} />
                        )}
                      </div>
                    ))}
                  </div>
                  <div className='mt-2 row justify-content-md-center justify-content-lg-start'>
                    {card.descriptionSpot.map((value: descriptionSpotProps, index: number) => (
                      <div key={index} className='d-flex align-items-center align-items-md-start col-md-6 col-lg-12'>
                        {value.icon &&
                          <div className='mr-2 mr-md-3 mr-lg-3'>
                            <OrangeIcon
                              size='MD'
                              color='#FF7A00'
                              icon={value.icon}
                            />
                          </div>
                        }
                        {value.hasLink ? (
                          <span
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                section_name: 'Cartões Inter Mastercard',
                                element_action: 'click button',
                                element_name: `${value.linkTitle} + ${card.card}` || 'null',
                                redirect_url: value.hasLink,
                              })
                            }}
                          >
                            <p
                              className='fs-16 lh-20 text-grayscale--400 fw-100 mt-3 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4'
                              dangerouslySetInnerHTML={{ __html: value.text }}
                            />
                          </span>
                        ) : (
                          <p
                            className='fs-16 lh-20 text-grayscale--400 fw-100 mt-3 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4'
                            dangerouslySetInnerHTML={{ __html: value.text }}
                          />
                        )}

                      </div>
                    ))}
                    <div className='col pt-3 pt-lg-0'>
                      <Button className='d-flex justify-content-center'>
                        {windowWidth < WIDTH_MD ? (
                          <a
                            href={oneLink}
                            target='_blank'
                            rel='noreferrer'
                            className='btn btn-orange--extra fs-14 fw-700 rounded-3'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                section_name: 'Cartões Inter Mastercard',
                                element_action: 'click button',
                                element_name: 'Solicitar cartão de crédito gratuito',
                                redirect_url: oneLink,
                              })
                            }}
                          >
                            Solicitar cartão de crédito gratuito
                          </a>
                        ) : (
                          <button
                            onClick={() => {
                              setIsModalOpen(true)
                              sendDatalayerEvent(dataLayer)
                              setDataLayer(dataLayer)
                            }}
                            className='btn btn-orange--extra btn--lg fs-12 lh-15 fw-700 rounded-3'
                          >
                            Solicitar cartão de crédito gratuito
                          </button>
                        )
                        }
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Tab>
      </div>
    </Benefits>
  )
}

export default Beneficios

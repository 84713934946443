import React from 'react'

import useWidth from 'src/hooks/window/useWidth'

import { Button } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

type modalProps = {
  setIsModalOpen: Function;
  setDataLayer: Function;
  oneLink: string;
}

const WIDTH_MD = 768

const Header = ({ setIsModalOpen, setDataLayer, oneLink }: modalProps) => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão de crédito internacional, gratuito e pra você',
    element_action: 'click button',
    element_name: 'Solicitar cartão de crédito gratuito',
  }

  return (
    <section id='cartao-inter' className='py-md-5 bg-white d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-0 order-md-last d-none d-md-block'>
            <ImageWebp
              arrayNumbers={[ 0, 300, 350, 400 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-cartoes-1440/image.webp'
              altDescription='Cartões Gold, Platinum e Black Inter'
              className='float-right'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-7 overflow-hidden align-items-center pr-xl-0'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 my-4 py-2 fw-600 text-grayscale--500'>
              Cartão de crédito internacional, grátis e com programa de pontos
            </h1>

            <p className='fs-18 lh-22 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 fw-700 fw-md-600 text-orange--extra mb-3 font-citrina'>
              Peça agora e simplifique a sua vida!
            </p>

            <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              <span className='d-xl-block'>O cartão Inter é sem anuidade, tem pagamento por aproximação e</span> te dá pontos para trocar por benefícios exclusivos.
            </p>
            <Button className='d-flex justify-content-center justify-content-lg-start mt-md-5'>
              {windowWidth < WIDTH_MD
                ? (
                  <a
                    href={oneLink}
                    target='_blank' rel='noreferrer'
                    className='btn btn-orange--extra fs-14 fw-700 rounded-3'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Cartão de crédito internacional, gratuito e pra você',
                        element_action: 'click button',
                        element_name: 'Solicitar cartão de crédito gratuito',
                        redirect_url: oneLink,
                      })
                    }}
                  >
                    Solicitar cartão de crédito gratuito
                  </a>
                )
                : (
                  <button
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent(dataLayer)
                      setDataLayer(dataLayer)
                    }}
                    className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3'
                  >
                    Solicitar cartão de crédito gratuito
                  </button>
                )
              }
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header

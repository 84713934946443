import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'

import { DebitAndCreditFunctionSection, Button } from './style'

import DebitAndCreditFunctionJSON from './../../assets/data/DebitAndCreditFunction.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

type ItemProps = {
  icon: string;
  description: string;
  OrangeDS?: boolean;
  hasLink?: boolean;
}

type modalProps = {
  setIsModalOpen: Function;
  setDataLayer: Function;
  oneLink: string;
}

const FuncoesDebitoCredito = ({ setIsModalOpen, setDataLayer, oneLink }: modalProps) => {
  const WIDTH_MD = 768
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Tudo o que sua vida financeira precisa, em um só cartão',
    element_action: 'click button',
    element_name: 'Solicitar cartão de crédito gratuito',
  }

  return (
    <DebitAndCreditFunctionSection id='sem-anuidade' className='bg-grayscale--100'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-lg-between'>

          <div className='col-12 col-md-6 col-lg-6 mt-xl-5'>
            <div className='justify-content-xl-end'>
              <div className='text-div text-xl-left pl-0'>
                <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 mb-3 mb-lg-4'>
                  Tudo o que sua vida <span className='d-lg-block'>financeira precisa, em </span>
                  um só cartão
                </h2>

                <div className='col-12 d-md-none d-flex justify-content-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-app-1440/image.webp'
                    altDescription='Tela de celular mostrando a home do Super App e o cartão Gold Inter'
                    arrayNumbers={[ 280, 300, 350, 500 ]}
                  />
                </div>
                <p className='fs-16 lh-18 fs-lg-18 lh-lg-22 text-grayscale--400 mt-4 mb-4'>
                  Mais vantagens para você comprar como, quando e onde quiser:
                </p>
              </div>
            </div>

            { DebitAndCreditFunctionJSON.map((item: ItemProps) => (
              <div key={item.icon}>
                <div className='d-flex flex-row'>
                  <div>
                    <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} className='mr-3' />
                  </div>
                  {item.hasLink ? (
                    <span
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_03',
                          section_name: 'Tudo o que sua vida financeira precisa, em um só cartão',
                          element_action: 'click button',
                          element_name: 'aproximação',
                          redirect_url: 'https://blog.inter.co/cartao-contactless-banco',
                        })
                      }}
                    >
                      <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </span>
                  ) : (
                    <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                  )}
                </div>
              </div>
              ))}

            <div className='mt-4 mt-lg-4'>
              <Button className='d-flex justify-content-center justify-content-lg-start mt-md-5'>
                {windowWidth < WIDTH_MD
                ? (
                  <a
                    href={oneLink}
                    target='_blank'
                    rel='noreferrer'
                    className='btn btn-orange--extra fs-14 fw-700 rounded-3'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        section_name: 'Tudo o que sua vida financeira precisa, em um só cartão',
                        element_action: 'click button',
                        element_name: 'Solicitar cartão de crédito gratuito',
                        redirect_url: oneLink,
                      })
                    }}
                  >
                    Solicitar cartão de crédito gratuito
                  </a>
                )
                : (
                  <button
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent(dataLayer)
                      setDataLayer(dataLayer)
                    }}
                    className='btn btn-orange--extra btn--lg fs-14 fw-700 rounded-3 mw-100'
                  >
                    Solicitar cartão de crédito gratuito
                  </button>
                  )
              }
              </Button>
            </div>
            <p className='fs-16 lh-18 fs-lg-18 lh-18 lh-lg-22 text-grayscale--400 mt-4'>Liberação de crédito sujeita a análise</p>
          </div>

          <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-6'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-app-1440/image.webp'
              altDescription='Tela de celular mostrando a home do Super App e o cartão Gold Inter'
              arrayNumbers={[ 280, 300, 350, 500 ]}
            />
          </div>
        </div>
      </div>
    </DebitAndCreditFunctionSection>
  )
}

export default FuncoesDebitoCredito

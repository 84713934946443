import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'

import Header from './sections/header/_header'
import Cashback from './sections/cashback/_cashback'
import FuncoesDebitoCredito from './sections/funcoes-debito-credito/_funcoes-debito-credito'
import CreditoNaHora from './sections/credito-na-hora/_credito-na-hora'
import CarteirasDigitais from './sections/carteiras-digitais/_carteiras-digitais'
import ContaDigital from './sections/conta-digital/_conte-digital'
import Beneficios from './sections/beneficios/_beneficios'
import Faq from './sections/faq/_faq'

import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'
import QRcode from './assets/images/qr_code_site_contadigital_aquisicao_lpcartoes.png'
import { Wrapper } from './style'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const Cartoes = () => {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Cartão de crédito internacional, gratuito e pra você',
    element_action: 'click button',
    element_name: 'Solicitar cartão de crédito gratuito',
  })

  const oneLink = 'https://inter-co.onelink.me/Qyu7/hc88cgqw'

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        dataLayer={dataLayer}
        qrBaixeApp={QRcode}
        agoraBaixeOAppLink={oneLink}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {cardsModal}
        <Header setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} oneLink={oneLink} />
        <Beneficios setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} oneLink={oneLink} />
        <FuncoesDebitoCredito setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} oneLink={oneLink} />
        <CreditoNaHora setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} oneLink={oneLink} />
        <Cashback />
        <CarteirasDigitais />
        <ContaDigital setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} oneLink={oneLink} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default Cartoes

import React from 'react'

// Components
import VerticalCarousel from 'src/components/UI/Carousels/VerticalCarousel'
import Icon from 'src/components/UI/MarkdownIcon'
import OrangeDs from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import CompleteDigitalAccountJSON from './../../assets/data/CompleteDigitalAccount.json'

import { CompleteDigitalAccount, Button } from './style'

type ItemProps = {
  icon: string;
  description: string;
  orangeDS?: boolean;
}

type ModalProps = {
  setIsModalOpen: Function;
  setDataLayer: Function;
  oneLink: string;
}

const WIDTH_MD = 768

const ContaDigital = ({ setIsModalOpen, setDataLayer, oneLink }: ModalProps) => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <CompleteDigitalAccount id='conta-digital-completa'>
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5 col-xl-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-lg-50  fw-600 text-grayscale--500 mb-3'>
              Ainda mais vantagens com uma Conta Digital gratuita
            </h2>
            <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 text-grayscale--400 mb-md-5'>Aqui, você não paga mensalidades e ainda faz <span className='d-xl-block'>transferências e Pix saques ilimitados. Confira todos os benefícios:</span>
            </p>
            <Button className='d-none d-lg-block'>
              <a
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra btn--lg fs-14 fw-700 rounded-3'
                onClick={() => {
                  setIsModalOpen(true)
                  setDataLayer({
                    section: 'dobra_07',
                    section_name: 'Ainda mais vantagens com uma Conta Digital gratuita',
                    element_action: 'click button',
                    element_name: 'Abrir conta gratuita',
                  })
                }}
              >
                Abrir conta gratuita
              </a>
            </Button>
          </div>
          <div className='col-12 col-lg-6 offset-lg-1 col-xl-5'>
            <VerticalCarousel
              sm={{
                    verticalItems: 2,
                    horizontalItems: 2,
              }}
              md={{
                    verticalItems: 4,
                    horizontalItems: 3,
              }}
              lg={{
                    verticalItems: 5,
                    horizontalItems: 2,
              }}
              xl={{
                    verticalItems: 5,
                    horizontalItems: 2,
              }}
              removeArrowOnDeviceType={[ 'desktop' ]}
            >
              { CompleteDigitalAccountJSON.map((item: ItemProps) => (
                <div className='d-flex flex-column align-items-md-center flex-md-row card-carousel mt-3 mt-md-0 pr-4' key={item.icon}>
                  <div>
                    {item.orangeDS
                    ? <OrangeDs icon={item.icon} size='MD' color='#FF7A00' />
                    : <Icon width='24' height='24' icon={item.icon} directory='v2' color='orange--base' />}
                  </div>
                  <p className='fs-16 lh-20 text-grayscale--500 fw-600 mt-2 mt-md-0 ml-md-4 mb-md-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
                  ))}
            </VerticalCarousel>
            <Button className='d-block d-md-flex d-lg-none mt-5 mt-md-0 justify-content-center'>
              <a
                href={oneLink} target='_blank' rel='noreferrer'
                className={`btn ${windowWidth < WIDTH_MD ? '' : 'btn--lg'} btn-orange--extra fs-14 fw-700 rounded-3`}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Ainda mais vantagens com uma Conta Digital gratuita',
                    element_action: 'click button',
                    element_name: 'Abrir conta gratuita',
                    redirect_url: oneLink,
                  })
                }}
              >
                Abrir conta gratuita
              </a>
            </Button>
          </div>
        </div>
      </div>
    </CompleteDigitalAccount>
  )
}

export default ContaDigital
